exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-action-index-jsx": () => import("./../../../src/pages/action/index.jsx" /* webpackChunkName: "component---src-pages-action-index-jsx" */),
  "component---src-pages-adult-index-jsx": () => import("./../../../src/pages/adult/index.jsx" /* webpackChunkName: "component---src-pages-adult-index-jsx" */),
  "component---src-pages-flow-index-jsx": () => import("./../../../src/pages/flow/index.jsx" /* webpackChunkName: "component---src-pages-flow-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-health-index-jsx": () => import("./../../../src/pages/health/index.jsx" /* webpackChunkName: "component---src-pages-health-index-jsx" */),
  "component---src-pages-kid-index-jsx": () => import("./../../../src/pages/kid/index.jsx" /* webpackChunkName: "component---src-pages-kid-index-jsx" */),
  "component---src-pages-price-index-jsx": () => import("./../../../src/pages/price/index.jsx" /* webpackChunkName: "component---src-pages-price-index-jsx" */),
  "component---src-pages-private-index-jsx": () => import("./../../../src/pages/private/index.jsx" /* webpackChunkName: "component---src-pages-private-index-jsx" */),
  "component---src-pages-talent-index-jsx": () => import("./../../../src/pages/talent/index.jsx" /* webpackChunkName: "component---src-pages-talent-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

